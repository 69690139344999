/* eslint-disable react/jsx-props-no-spreading */
import Link, { type LinkProps } from 'next/link';
import React, { type ComponentProps, forwardRef, type RefObject } from 'react';
import styles from './GlobalIconButton.module.scss';

type TGlobalIconButtonProps = { width: number; height: number; 'aria-label': string } & (
	| ({ elementType: 'Button' } & React.ComponentProps<'button'>)
	| ({ elementType: 'Link' } & LinkProps & Omit<ComponentProps<'a'>, 'href'>)
);

const globalIconButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, TGlobalIconButtonProps>((props, ref) => {
	if (props.elementType === 'Link') {
		const { width, height, href, className, elementType, children, 'aria-label': ariaLabel, ...otherProps } = props;
		const style = { '--__width': `${width}px`, '--__height': `${height}px` } as React.CSSProperties;
		return (
			<Link
				aria-label={ariaLabel}
				style={style}
				className={`${styles['btn']} ${className || ''}`.trim()}
				href={href}
				ref={ref as RefObject<HTMLAnchorElement>}
				data-element-type={elementType}
				{...otherProps}
			>
				{children}
			</Link>
		);
	}
	if (props.elementType === 'Button') {
		const { width, height, className, elementType, children, 'aria-label': ariaLabel, ...otherProps } = props;
		const style = { '--__width': `${width}px`, '--__height': `${height}px` } as React.CSSProperties;
		return (
			<button
				aria-label={ariaLabel}
				style={style}
				className={`${styles['btn']} ${className || ''}`.trim()}
				type="button"
				data-element-type={elementType}
				ref={ref as RefObject<HTMLButtonElement>}
				{...otherProps}
			>
				{children}
			</button>
		);
	}
	return null;
});

globalIconButton.displayName = 'globalIconButton';
export { globalIconButton as GlobalIconButton };
