'use client';
import { GlobalIconButton } from '@/components/UI/buttons';
import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa6';
import { MdEmail, MdOutlinePhoneIphone, MdWhatsapp } from 'react-icons/md';
import styles from './Contact.module.scss';

type TContactProps = Readonly<{ email: string; phone: string; gitHub: string; linkedin: string; whatsApp: string }>;

function Contact(this: unknown, { email, phone, gitHub, linkedin, whatsApp }: TContactProps): React.JSX.Element {
	function onClickHandler(link: string): void {
		window.location.href = link;
	}
	const iconWidth = 40;
	const iconHeight = 40;

	return (
		<div className={styles['container']}>
			<GlobalIconButton
				aria-label="send me an email"
				className={`${styles['btn--icon']}`}
				elementType="Button"
				height={iconHeight}
				width={iconWidth}
				onClick={() => {
					onClickHandler('mailto:' + email);
				}}
			>
				<MdEmail />
			</GlobalIconButton>
			<GlobalIconButton
				aria-label="call me"
				className={`${styles['btn--icon']}`}
				elementType="Button"
				height={iconHeight}
				width={iconWidth}
				onClick={() => {
					onClickHandler('tel:' + phone);
				}}
			>
				<MdOutlinePhoneIphone />
			</GlobalIconButton>
			<GlobalIconButton
				aria-label="send me a message via whatsapp"
				className={`${styles['btn--icon']} ${styles['btn--icon--whatsapp']}`}
				elementType="Button"
				height={iconHeight}
				width={iconWidth}
				onClick={() => {
					onClickHandler('https://' + whatsApp);
				}}
			>
				<MdWhatsapp />
			</GlobalIconButton>
			<GlobalIconButton
				aria-label="follow me on github"
				className={`${styles['btn--icon']} ${styles['btn--icon--github']}`}
				elementType="Link"
				height={iconHeight}
				width={iconWidth}
				href={{ host: gitHub, protocol: 'https' }}
				target="_blank"
			>
				<FaGithub />
			</GlobalIconButton>
			<GlobalIconButton
				aria-label="connect with me on linkedin"
				className={`${styles['btn--icon']} ${styles['btn--icon--linkedin']}`}
				elementType="Link"
				height={iconHeight}
				width={iconWidth}
				href={{ host: linkedin, protocol: 'https' }}
				target="_blank"
			>
				<FaLinkedin />
			</GlobalIconButton>
		</div>
	);
}

export { Contact };
