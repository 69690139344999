/* eslint-disable react/jsx-props-no-spreading */

import Link, { LinkProps } from 'next/link';
import React, { ComponentProps, forwardRef, type RefObject } from 'react';
import { CgSpinner, CgSpinnerTwoAlt } from 'react-icons/cg';
import styles from './GlobalButton.module.scss';

function generateClassName(props: { className?: string; withIcon?: boolean; withSpinner?: boolean }): string {
	const classes = [styles['btn']];
	if (props?.withIcon || props?.withSpinner) classes.push(styles['btn__with-icon']);
	if (props?.withSpinner) classes.push(styles['btn__with-icon--spin']);
	if (props?.className) classes.push(props.className);
	return classes.join(' ');
}

type TGlobalButtonProps = Readonly<{
	children: React.ReactNode;
	'aria-label': string;
	btnSize?: 'small' | 'medium' | 'large';
	btnPosition?: 'stretch' | 'start' | 'end' | 'center';
	withoutBorder?: boolean;
	isDisabled?: boolean;
	withSpinner?: boolean;
	withIcon?: boolean;
	iconClassName?: string;
}> &
	(
		| ({ elementType: 'Button' } & React.ComponentProps<'button'>)
		| ({ elementType: 'Link' } & LinkProps & Omit<ComponentProps<'a'>, 'href'>)
	);

const globalButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, TGlobalButtonProps>((props, ref) => {
	if (props.elementType === 'Link') {
		const {
			btnSize,
			children,
			isDisabled,
			className,
			btnPosition,
			withoutBorder,
			withSpinner = false,
			withIcon = false,
			elementType,
			'aria-label': ariaLabel,
			href,
			...otherProps
		} = props;
		return (
			<Link
				aria-label={ariaLabel}
				aria-disabled={isDisabled}
				ref={ref as RefObject<HTMLAnchorElement>}
				className={generateClassName({ className, withIcon, withSpinner })}
				href={!isDisabled ? href : { href: undefined }}
				data-btn-size={btnSize || 'medium'}
				data-btn-position={btnPosition || 'start'}
				data-btn-border={withoutBorder ? 'no-border' : 'border'}
				data-with-icon={withSpinner || withIcon || 'false'}
				data-element-type={elementType}
				{...otherProps}
			>
				{withSpinner ? <CgSpinnerTwoAlt /> : null}
				{children}
			</Link>
		);
	}
	if (props.elementType === 'Button') {
		const {
			btnSize,
			children,
			isDisabled,
			className,
			btnPosition,
			withoutBorder,
			withSpinner = false,
			withIcon = false,
			elementType,
			'aria-label': ariaLabel,
			...otherProps
		} = props;

		return (
			<button
				aria-label={ariaLabel}
				disabled={isDisabled}
				ref={ref as RefObject<HTMLButtonElement>}
				className={generateClassName({ className, withIcon, withSpinner })}
				type={props.type}
				data-btn-size={btnSize || 'medium'}
				data-btn-position={btnPosition || 'start'}
				data-btn-border={withoutBorder ? 'no-border' : 'border'}
				data-with-icon={withSpinner || withIcon || 'false'}
				data-element-type={elementType}
				{...otherProps}
			>
				{withSpinner ? <CgSpinner /> : null}
				{children}
			</button>
		);
	}
	return null;
});

globalButton.displayName = 'globalButton';

export { globalButton as GlobalButton };
