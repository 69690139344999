import { type PayloadAction } from '@reduxjs/toolkit';
import { type TMenuState } from './state';

export type TSetHastPayload = NonNullable<TMenuState['hash']>;

class MenuActions {
	constructor(private _state: TMenuState) {}

	setHashAction(action: PayloadAction<TSetHastPayload>): void {
		const { payload } = action;
		this._state.hash = payload;
	}
}

export { MenuActions };
