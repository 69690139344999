import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { MenuActions, type TSetHastPayload } from './actions';
import { MENU_STATE } from './state';

const slice = createSlice({
	name: 'currentUser',
	initialState: MENU_STATE,
	reducers: {
		setHash: (state, action: PayloadAction<TSetHastPayload>) => new MenuActions(state).setHashAction(action)
	}
});

export const { setHash } = slice.actions;
export const { reducer: menuReducer } = slice;
