'use client';
import { setHash, useAppDispatch } from '@/redux';
import React, { useLayoutEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import styles from './Observer.module.scss';

type TObProps = Readonly<{
	link: string;
}>;

function Observer({ link }: TObProps): React.JSX.Element {
	const dispatch = useAppDispatch();
	const { ref, isIntersecting } = useIntersectionObserver({ threshold: 0.1 });

	useLayoutEffect(() => {
		if (isIntersecting) {
			dispatch(setHash(link));
		}
	}, [dispatch, link, isIntersecting]);

	return <div ref={ref} className={styles['container']}></div>;
}

export { Observer };
