import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.5/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/contact/Contact.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/itemList/ListItem.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/largeHero/LargeHero.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/observer/Observer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/UI/section/Section.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/pagesComponents/sectionTitle/SectionTitle.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/public/images/large-hero.jpg");
